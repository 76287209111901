import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

import "bootstrap";
import "../stylesheets/application"

import "@fortawesome/fontawesome-free/js/all";

global.toastr = require("toastr")

Rails.start();
ActiveStorage.start();
